import axios from 'axios';
import store from '../store/index';

export default function setup() {
    // Add authorization to request if loggedIn and token is stored
    axios.interceptors.request.use(function (config) {
        // Do something before request is sent
        const token = store.getters['auth/token'];
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        config.headers.Locale = store.state.language.current;

        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;

    }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
            
        // Clear local storage user if unauthorized
        if (error.response && error.response.status == 401) {
            localStorage.removeItem('user');
        }
            
        // Reload if it's set
        if (error.response && error.response.data.reload) {
            location.reload();
        }
            
        return Promise.reject(error);
    });
}