<script>
    export default {
        functional: true,
        props: {
            icon: {
                type: String,
                default: ''
            },
            iconClasses: {
                type: String,
                default: ''
            }
        },
        render(createElement, {
            props
        }) {
            let svgHTML = '';

            let iconClasses = `icon icon--${props.icon}`;
            if (props.iconClasses != '') {
                iconClasses = iconClasses + ' ' + props.iconClasses;
            }
            try {
                svgHTML = require(`!html-loader!@/iconfont/${props.icon}.svg`);
            } catch (error) {
                console.warn(`Unable to load "icon-${props.icon}.svg" icon. Verify it exists in the icons directory.`, error);
            }
            return createElement(
                'span', {
                    class: iconClasses,
                    domProps: {
                        innerHTML: svgHTML
                    }
                }
            );
        }
    };
</script>
<style>
    .icon {
        display: inline-block;
    }
</style>