<template>
    <div :class="(!cat) ? 'tree-list' : 'tree-list__item'">
        <div v-if="!cat" class="tree-list__brand">
            {{ '#' + brand.id + ' ' + brand.name }}
            <div class="tree-list__actions">
                <button class="button-icon" @click="addCategory(brand.id, null)">
                    <icon icon="plus"/>
                </button>
            </div>
        </div>
        <div v-else class="tree-list__cat">
            <div>{{ '#' + cat.id + ' ' + cat.name }}</div>
            <div class="tree-list__actions">
                <button class="button-icon" @click="addCategory(cat.brand_id, cat.id)">
                    <icon icon="plus"/>
                </button>

                <button class="button-icon button-icon--ml-10" @click="editCategory(cat, brand)">
                    <icon icon="edit"/>
                </button>

                <button class="button-icon button-icon--ml-10" @click="deleteCategory(cat)">
                    <icon icon="trash"/>
                </button>
            </div>
        </div>

        <categories-list v-for="category in categories" :categories="category.subcategories" :cat="category" :brand="brand"
            :key="'category_' + category.id">
        </categories-list>
    </div>
</template>


<script>
    import Icon from './Icon';
    export default {
        props: ['cat', 'categories', 'brand'],
        name: 'categories-list',
        components: {
            Icon
        },
        methods: {
            async addCategory(brandId, categoryId) {
                this.$root.$emit('addCategory', brandId, categoryId);
            },
            async editCategory(category, brand) {
                let object = {
                    category : category,
                    brand : brand
                };
                this.$root.$emit('editCategory', object);
            },
            async deleteCategory(category) {
                this.$root.$emit('deleteCategory', category);
            }
        }
    }
</script>