<template>
    <div>

        <div class="content-top row">
            <div class="col-24 d-flex justify-content-between align-items-center">
                <div class="content-top__title-box">
                    <h1 class="content-top__heading">Lista typów plików</h1>
                    <div class="content-top__subheading">{{ objects.length }}
                        {{ objects.length | numeralsText('typ pliku', 'typy plików', 'typów plików') }}
                    </div>
                </div>
            </div>
        </div>

        <div class="content col-24">

            <table class="items-table items-table--shrink">
                <thead class="items-table__thead">
                    <tr class="items-table__tr">
                        <th class="items-table__th">
                            ID
                        </th>
                        <th class="items-table__th">
                            Ikona
                        </th>
                        <th class="items-table__th">
                            Nazwa
                        </th>
                        <th class="items-table__th">
                            Rozszerzenie
                        </th>
                        <th class="items-table__th p-0 text-right">
                            Akcje
                        </th>
                    </tr>
                </thead>

                <tbody class="items-table__tbody">
                    <template v-for="object in objects">
                        <tr class="items-table__tr" v-bind:key="object.id">
                            <td class="items-table__td">
                                <div class="form-box__mobile-element">Id</div>
                                {{ object.id }}
                            </td>

                            <td class="items-table__td items-table__td--icon">

                                <div v-if="editingObject.key == object.id">
                                    <div class="button button--grey" v-if="editingObjectUrl == null" @click="$refs.fileEditInput.click()">
                                        <icon icon="export" icon-classes="icon--regular"/>
                                    </div>
                                    <img v-else :src="editingObjectUrl" class="items-table__icon items-table__icon--hover-edit" @click="$refs.fileEditInput.click()"/>
                                </div>
                                <div v-else>
                                    <img class="items-table__icon" :src="getApiPath(object.icon)"/>
                                </div>

                            </td>

                            <td class="items-table__td">
                                <div class="form-box__mobile-element">Nazwa</div>
                                <div v-if="editingObject.key == object.id">
                                    <input class="items-table__input" type="text"
                                        v-model="editingObject.name"  @keypress.enter.prevent="saveObject()"/>
                                </div>
                                <div v-else>
                                    {{ object.name }}
                                </div>
                            </td>

                            <td class="items-table__td">
                                <div class="form-box__mobile-element">Rozszerzenie</div>
                                <div v-if="editingObject.key == object.id">
                                    <input class="items-table__input" type="text"
                                        v-model="editingObject.ext" @keypress.enter.prevent="saveObject()"/>
                                </div>
                                <div v-else>
                                    {{ object.ext | lowercase }}
                                </div>
                            </td>

                            <td class="items-table__td items-table__td--button">
                                <div class="form-box__mobile-element">Akcje</div>
                                <div class="d-flex justify-content-end">
                                    <button v-if="editingObject.key == object.id" class="button-icon"
                                        @click.prevent="saveObject()">
                                        <icon icon="save" />
                                    </button>
                                    <button v-else class="button-icon" @click.prevent="editObject(object)">
                                        <icon icon="edit" />
                                    </button>

                                    <button class="button-icon button-icon--ml-10" @click="deleteObject(object)">
                                        <icon icon="trash" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </template>
                    <tr>
                        <td class="items-table__td">
                            <div class="form-box__mobile-element"></div>
                            #
                        </td>
                        <td class="items-table__td">
                            <div class="form-box__mobile-element"></div>
                            <div>
                                <div class="button button--grey" v-if="newObjectUrl == null" @click="$refs.fileInput.click()">
                                    <icon icon="export" icon-classes="icon--regular"/>
                                </div>
                                <img v-else :src="newObjectUrl" style="width: 22px; display: block;" @click="$refs.fileInput.click()"/>
                            </div>
                        </td>
                        <td class="items-table__td">
                            <div class="form-box__mobile-element">Nazwa</div>
                            <input v-model="newObject.name" class="items-table__input" @keypress.enter.prevent="storeObject()"/>
                        </td>
                        <td class="items-table__td">
                            <div class="form-box__mobile-element">Rozszerzenie</div>
                            <input v-model="newObject.ext" class="items-table__input" @keypress.enter.prevent="storeObject()"/>
                        </td>
                        <td class="items-table__td items-table__td--button">
                            <div class="form-box__mobile-element"></div>
                            <div class="d-flex justify-content-end">
                                <button class="button button--icon" @click="storeObject()">
                                    <icon icon="plus"
                                        icon-classes="button__icon button__icon--left icon--white icon__plus--small" />
                                    <span class="button__text">Dodaj nowy typ</span>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <input id="file-upload" type="file" @change="onFileChange" ref="fileInput" style="display: none;"/>
            <input id="file-edit" type="file" @change="onEditFileChange" ref="fileEditInput" style="display: none;"/>

        </div>

        <div>Możesz użyć * jako rozszerzenia, aby przypisywać ten typ dla wszystkich niezidentyfikowanych rozszerzeń.</div>

        <!-- Modal delete -->
        <modal :show="showModal" size="md" @close="showModal = !showModal">
            <div slot="header" class="notice-modal__header--title">
                Usuwanie typu pliku
            </div>

            <div slot="body">
                <div>Czy na pewno chcesz usunąć typ plików
                    <strong>{{ '#' + objectToDelete.id + ' - ' + objectToDelete.name }}</strong>?</div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--small button--grey" @click="showModal = false">
                    Nie
                </button>
                <button class="button button--small button--ml-10" @click="confirmDeleteObject()">
                    Tak
                </button>
            </div>
        </modal>
    </div>
</template>

<script>
    import axios from 'axios';
    const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
    const API_PHOTO_URL = process.env.VUE_APP_API_PHOTO_URL;
    import Icon from './../parts/Icon';
    import Modal from './../parts/Modal';

    export default {
        data() {
            return {
                objects: {},
                newObject: {},
                newObjectUrl: null,
                showModal: false,
                editingObject: {},
                editingObjectUrl: null,
                objectToDelete: {}
            }
        },
        components: {
            Icon,
            Modal
        },
        async created() {
            this.getObjects();
        },
        methods: {
            async storeObject(data) {
                let vm = this;
                if (!data) data = this.newObject;

                try {

                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }

                    let formData = new FormData();
                    if (data.file) {
                        formData.append('file', data.file);
                    }
                    if (data.name) {
                        formData.append('name', data.name);
                    }
                    if (data.ext) {
                        formData.append('ext', data.ext);
                    }
                    if (data.id) {
                        formData.append('id', data.id);
                    }

                    const response = await axios.post(API_BASE_URL + '/files/types/store', formData, config);
                    vm.objects = response.data.list;
                    vm.newObject = {
                        url: null
                    };
                    this.newObjectUrl = null;
                    this.editingObject = {};
                    this.editingObjectUrl = null;

                } catch (error) {
                    vm.$root.$emit('noticesBox', error.response.data);
                }
            },
            async getObjects() {
                try {
                    const response = await axios.get(API_BASE_URL + '/files/types');
                    this.objects = response.data;
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            onFileChange(event) {
                this.newObject.file = event.target.files[0];
                let url = URL.createObjectURL(this.newObject.file);
                this.newObjectUrl = url;
                const input = this.$refs.fileInput;
                input.type = 'text';
                input.type = 'file';
            },
            onEditFileChange(event) {
                this.editingObject.file = event.target.files[0];
                let url = URL.createObjectURL(this.editingObject.file);
                this.editingObjectUrl = url;
                const input = this.$refs.fileEditInput;
                input.type = 'text';
                input.type = 'file';
            },
            getApiPath(path) {
                if (path) {
                    let timestamp = new Date().getTime();

                    return API_PHOTO_URL + path + '?' + timestamp;
                } else return require('../../iconfont/default-file-icon.svg');
            },
            async deleteObject(object) {
                this.showModal = true;
                this.objectToDelete = object;
            },
            async confirmDeleteObject() {
                this.showModal = false;
                try {
                    await axios.delete(API_BASE_URL + '/files/types/delete/' + this.objectToDelete.id);
                    this.getObjects();
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            editObject(object) {
                this.editingObject = object;
                this.editingObject.key = object.id;
                this.editingObjectUrl = (object.icon) ? this.getApiPath(object.icon) : null;
            },
            async saveObject() {
                this.storeObject(this.editingObject);
            }
        }
    }
</script>