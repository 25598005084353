import AuthService from '../../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { status: { loggedIn: true }, user } : { status: {}, user: null };

export const auth = {
    namespaced: true,
    state: initialState,
    getters: {
        loggedIn: state => {
            return (state.user != null && typeof state.user.loggedIn !== 'undefined') ? state.user.loggedIn : false;
        },
        token: state => {
            return (state.user != null && typeof state.user.token !== 'undefined') ? state.user.token : false;
        }
    },
    actions: {
        login({
            commit
        }, user) {
            return AuthService.login(user).then(
                user => {
                    commit('loginSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error.response.data);
                }
            );
        },
        logout({
            commit
        }, user) {
            return AuthService.logout(user).then(
                user => {
                    commit('logout', user);
                    return Promise.resolve(user);
                },
                error => {
                    return Promise.reject(error.response.data);
                }
            );
        }
    },
    mutations: {
        loginSuccess(state, user) {
            state.status = {
                loggedIn: true
            };
            state.user = user;
        },
        loginFailure(state) {
            state.status = {};
            state.user = null;
        },
        logout(state) {
            state.status = {};
            state.user = null;
        }
    }
};