import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';
import axios from 'axios';
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

Vue.use(VueLocalStorage);

export const language = {
    namespaced: true,
    state: {
        current: Vue.localStorage.get('current'),
        all: JSON.parse(Vue.localStorage.get('all'))
    },
    mutations: {
        SET_LANGUAGE(state, lang) {
            Vue.localStorage.set('current', lang.code);
            state.current = lang.code;

            if (!lang.preventReload) {
                location.reload();
            }
        },
        FETCH_LANGUAGES(state, languages) {
            Vue.localStorage.set('all', JSON.stringify(languages));
            state.all = languages;
        }
    },
    actions: {
        setLanguage({commit}, lang) {
            commit('SET_LANGUAGE', lang);
        },
        fetchLanguages({commit}){
            try {
                axios.get(API_BASE_URL + '/languages').then((response) => {
                    commit("FETCH_LANGUAGES", response.data);
                });
            } catch (error) {
                this.$root.$emit('noticesBox', error.response.data);
            }
        }
    },
};