<template>
    <div v-if="languages">
        <div class="d-flex justify-content-end language-switcher">
            <div class="language-switcher__lang" :class="{ 'is-active' : $store.state.language.current == lang.code }"
                v-for="lang in languages" :key="'lang_' + lang.code" @click="switchLang(lang)">
                {{ lang.code | uppercase }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                languages: this.$store.state.language.all
            }
        },
        methods: {
            switchLang(lang) {
                this.$store.dispatch('language/setLanguage', lang);
            }
        }
    };
</script>