<template>
    <div>

        <div class="content-top row">
            <div class="col-24 d-flex justify-content-between align-items-center">
                <div class="content-top__title-box">
                    <h1 class="content-top__heading">Lista brandów produktów</h1>
                    <div class="content-top__subheading">{{ objects.length }}
                        {{ objects.length | numeralsText('brand produktów', 'brandy produktów', 'brandów produktów') }}
                    </div>
                </div>
            </div>
        </div>

        <div class="content row">
            <div class="col-24">
                <table class="items-table items-table--shrink">
                    <thead class="items-table__thead">
                        <tr class="items-table__tr">
                            <th class="items-table__th">
                                ID
                            </th>
                            <th class="items-table__th">
                                Nazwa
                            </th>
                            <th class="items-table__th p-0 text-right">
                                Akcje
                            </th>
                        </tr>
                    </thead>

                    <tbody class="items-table__tbody">
                        <template v-for="object in objects">
                            <tr class="items-table__tr" v-bind:key="'object_' + object.id">
                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">Id</div>
                                    {{ object.id }}
                                </td>

                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">Nazwa</div>
                                    <div v-if="editingObject.key == object.id">
                                        <input type="text" v-model="editingObject.name" @keypress.enter.prevent="saveObject()"/>
                                    </div>
                                    <div v-else>
                                        {{ object.name }}
                                    </div>
                                </td>

                                <td class="items-table__td items-table__td--button">
                                    <div class="form-box__mobile-element">Akcje</div>
                                    <div class="d-flex justify-content-end">
                                        <button v-if="editingObject.key == object.id" class="button-icon"
                                            @click.prevent="saveObject()">
                                            <icon icon="save" />
                                        </button>
                                        <button v-else class="button-icon" @click.prevent="editObject(object)">
                                            <icon icon="edit" />
                                        </button>

                                        <button class="button-icon button-icon--ml-10" @click="deleteObject(object)">
                                            <icon icon="trash" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <tr>
                            <td class="items-table__td">
                                <div class="form-box__mobile-element"></div>
                                #
                            </td>
                            <td class="items-table__td">
                                <div class="form-box__mobile-element">Nazwa</div>
                                <input v-model="newObject.name" class="items-table__input" @keypress.enter.prevent="storeObject()"/>
                            </td>
                            <td class="items-table__td items-table__td--button">
                                <div class="form-box__mobile-element"></div>
                                <div class="d-flex justify-content-end">
                                    <button class="button button--icon" @click="storeObject()">
                                        <icon icon="plus"
                                            icon-classes="button__icon button__icon--left icon--white icon__plus--small" />
                                        <span class="button__text">Dodaj nową markę</span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Modal delete -->
        <modal :show="showModal" size="md" @close="showModal = !showModal;">
            <div slot="header" class="notice-modal__header--title">
                Usuwanie brand produktów
            </div>

            <div slot="body">
                <div>Czy na pewno chcesz usunąć brand <strong>{{ '#' + objectToDelete.id + ' - ' + objectToDelete.name }}</strong>?</div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--small button--grey" @click="showModal = false">
                    Nie
                </button>
                <button class="button button--small button--ml-10" @click="confirmDeleteObject()">
                    Tak
                </button>
            </div>
        </modal>

    </div>
</template>

<script>
    import axios from 'axios';
    const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
    import Icon from './../parts/Icon';
    import Modal from './../parts/Modal';

    export default {
        data() {
            return {
                objects: {},
                newObject: {},
                editingObject: {},
                showModal: false,
                objectToDelete: {}
            }
        },
        async created() {
            this.getObjects();
        },
        components: {
            Icon,
            Modal
        },
        methods: {
            async getObjects() {
                try {
                    const response = await axios.get(API_BASE_URL + '/product-brands');
                    this.objects = response.data;
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            async storeObject(data = false) {
                if (!data) data = this.newObject;

                try {
                    const response = await axios.post(API_BASE_URL + '/product-brands', data);
                    this.newObject = {};
                    this.objects = response.data;
                    this.editingObject = {};
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            editObject(object) {
                this.editingObject = object;
                this.editingObject.key = object.id;
            },
            saveObject() {
                this.storeObject(this.editingObject);
            },
            async confirmDeleteObject() {
                this.showModal = false;
                try {
                    await axios.delete(API_BASE_URL + '/product-brands/' + this.objectToDelete.id);
                    this.getObjects();
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            deleteObject(object) {
                this.showModal = true;
                this.objectToDelete = object;
            }
        }
    }
</script>