<template>
    <div class="row justify-content-center">
        <ul class="pagination" v-if="pagination.last_page > 1">

            <li class="pagination__item pagination__item--arrow"
                :class="{'pagination__item--is-disabled' : pagination.current_page <= 1}">
                <button class="pagination__button pagination__button--previous"
                    @click.prevent="changePage(pagination.current_page - 1)">
                    <icon icon="chevron" />
                </button>
            </li>

            <li class="pagination__item" v-for="page in pagesNumber" :key="page"
                :class="{'pagination__item--active': page == pagination.current_page}">
                <button class="pagination__button" @click.prevent="changePage(page)">{{ page }}</button>
            </li>

            <li class="pagination__item pagination__item--arrow"
                :class="{'pagination__item--is-disabled' : pagination.current_page == pagination.last_page}">
                <button class="pagination__button" aria-label="Next"
                    @click.prevent="changePage(pagination.current_page + 1)">
                    <icon icon="chevron" />
                </button>
            </li>

        </ul>
    </div>
</template>

<script>
    import Icon from './Icon';

    export default {
        props: {
            pagination: {
                type: Object,
                required: true
            },
            offset: {
                type: Number,
                default: 4
            }
        },
        components: {
            Icon
        },
        computed: {
            pagesNumber() {
                if (!this.pagination.to) {
                    return [];
                }
                let from = this.pagination.current_page - this.offset;
                if (from < 1) {
                    from = 1;
                }
                let to = from + (this.offset * 2);
                if (to >= this.pagination.last_page) {
                    to = this.pagination.last_page;
                }
                let pagesArray = [];
                for (let page = from; page <= to; page++) {
                    pagesArray.push(page);
                }
                return pagesArray;
            }
        },
        methods: {
            changePage(page) {
                this.pagination.current_page = page;
                this.$emit('paginate');
            }
        }
    }
</script>